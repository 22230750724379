




















































































































































import { Vue } from "vue-property-decorator";
import { timeFormat } from "@/utils";
import ReactionsComponent from "@/components/ReactionsComponent.vue";
import Loader from "@/components/Loader.vue";
import {
  CREATE_COMMENT_MUTATION,
  DELETE_COMMENT_MUTATION,
  GET_COMMENTS_QUERY,
  USER_DAY_COMMENTS_QUERY,
  GET_TOP_COMMENTS_QUERY
} from "@/constants/graphql";
import { Comment } from "@/types";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
Vue.component("loader", Loader);
Vue.component("reactions", ReactionsComponent);

export default Vue.extend({
  name: "CommentsComponent" as string,
  props: ["legislation", "loc"],
  data() {
    return {
      newComments: ([] as unknown) as [Comment],
      topComments: ([] as unknown) as [Comment],
      userDayComments: [8],
      commentDraft: "",
      orderby: "created_at"
    };
  },
  computed: {
    leg(): string {
      return this.legislation.packageid;
    },
    comments() {
      return this.$data.orderby == "created_at"
        ? this.$data.newComments
        : this.$data.topComments;
    },
    verified(): boolean {
      return this.$store.getters.authLoading
        ? false
        : this.$store.getters.verified;
    },
    provision() {
      return this.$store.state.provision;
    },
    udc() {
      return Math.min(
        ...[...Array(6).keys()].filter(
          d => !this.$data.userDayComments.includes(d)
        )
      );
    }
  },
  watch: {
    provision: function(newVal, oldVal) {
      if (newVal != undefined) {
        document.getElementById("newcomment")?.focus();
      }
    }
  },
  filters: {
    timeFormat
  },
  apollo: {
    newComments: {
      query: GET_COMMENTS_QUERY,
      variables() {
        return {
          packageid: this.legislation.packageid,
          userid: this.$store.getters.isLoggedIn ? this.$store.state.user.id : 0
        };
      },
      skip(): {} {
        return this.orderby != "created_at";
      },
      update: data => data.comments,
      fetchPolicy: "no-cache"
    },
    topComments: {
      query: GET_TOP_COMMENTS_QUERY,
      variables() {
        return {
          packageid: this.legislation.packageid,
          userid: this.$store.getters.isLoggedIn ? this.$store.state.user.id : 0
        };
      },
      skip(): {} {
        return this.orderby != "upvotes_count";
      },
      update: data => data.comments,
      fetchPolicy: "no-cache"
    },
    userDayComments: {
      query: USER_DAY_COMMENTS_QUERY,
      variables() {
        return {
          userid: this.$store.state.user.id,
          date: new Date().toISOString()
        };
      },
      update: data => data.comments.map(d => d.user_day_comment),
      skip() {
        return !this.$store.getters.verified;
      }
    }
  },
  methods: {
    resetloc() {
      this.$store.dispatch("setprovision", { provision: undefined });
    },
    toLoc(provision) {
      this.$store.dispatch("setlocation", { loc: provision.location });
    },
    async submitComment() {
      const commentstr = this.commentDraft;
      if (this.udc == 5) {
        return;
      }
      this.commentDraft = "";
      const packageid = this.leg;
      // Call to the graphql mutation
      const token = await this.$store.dispatch("getToken");
      const userId = this.$store.state.user.id;
      this.$apollo
        .mutate({
          mutation: CREATE_COMMENT_MUTATION,
          variables: {
            userid: userId,
            packageid: packageid,
            comment: commentstr,
            provisiontag: this.provision?.provision_tag,
            userDayComment: this.udc
          },
          context: {
            headers: {
              Authorization: `Bearer ${token}`
            }
          },
          refetchQueries: ["GetComments"]
        })
        .then(response => {
          // Result
          console.log(response);
          this.userDayComments.push(this.udc);
        })
        .catch(e => {
          // Error
          console.error(e);
          this.$store.dispatch("setnotification", {
            notification: { error: e }
          });
        });
    },
    async deleteComment(comment: Comment) {
      console.log("delete:", comment);
      const token = await this.$store.dispatch("getToken");
      this.$apollo
        .mutate({
          mutation: DELETE_COMMENT_MUTATION,
          variables: {
            id: comment.comment_id,
            deletedAt: new Date().toISOString()
          },
          context: { headers: { Authorization: `Bearer ${token}` } },
          refetchQueries: ["GetComments"]
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
});
