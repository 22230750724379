





















































































import { Vue } from "vue-property-decorator";
import { Bill } from "@/types";
import BillCard from "@/components/BillCard.vue";
import { uniquebills } from "@/utils";
import Loader from "@/components/Loader.vue";
import Tabs from "@/components/Tabs.vue";
import { BILLS_QUERY } from "@/constants/graphql";
import { versions } from "@/utils";
import Pagination from "@/components/Pagination.vue";
import BillFilters from "@/components/BillFilters.vue";
Vue.component("bill", BillCard);
Vue.component("loader", Loader);
Vue.component("tabs", Tabs);
Vue.component("pagination", Pagination);
Vue.component("filters", BillFilters);

export default Vue.extend({
  name: "Home" as string,
  props: {
    tab: {
      type: String,
      required: false,
      default: "popular"
    }
  },
  data() {
    return {
      panelHidden: false,
      bills: [] as Bill[],
      loading: false,
      currenttab: this.tab,
      page: 1,
      tabs: [
        {
          label: "Popular",
          value: "popular",
          orderby: "{ upvotes_count: desc, num_cosponsors: desc }",
          mode: "home"
        },
        {
          label: "New",
          value: "new",
          orderby: "{ date_issued: desc }",
          mode: "home"
        },
        {
          label: "Active This Week",
          value: "activeWeek",
          orderby: "{votes_aggregate: {count: desc}}",
          mode: "home"
        }
      ],
      filters: {
        sponsor: "",
        cosponsor: "",
        sponsorcosponsor: true,
        billstatuses: [...new Set(Object.values(versions).map(d => d.brief))],
        billtypes: ["hr", "s", "hjres", "sjres"],
        congress: [116, 117],
        number: null
      }
    };
  },
  computed: {
    uniquebills() {
      const bills = uniquebills(this.bills);
      return bills;
    }
  },
  watch: {
    currenttab() {
      this.updatefilters();
    },
    page() {
      this.search();
    }
  },
  methods: {
    updatefilters() {
      if (this.page > 1) {
        this.page = 1;
      } else {
        this.search();
      }
    },
    search() {
      const weekago = new Date();
      weekago.setDate(weekago.getDate() - 30);
      const tab = this.tabs.filter(d => d.value == this.currenttab)[0];
      const orderby = tab.orderby;
      this.loading = true;
      const addlfilters =
        this.currenttab == "activeWeek"
          ? `{ votes: { created_at: { _gt: "${weekago.toISOString()}" } } }`
          : "";
      this.$apollo
        .query({
          query: BILLS_QUERY(orderby, addlfilters),
          variables: {
            limit: 30,
            offset: 30 * (this.page - 1),
            congress: this.filters.congress,
            billtype: this.filters.billtypes,
            max: this.filters.number ? this.filters.number : 10 ** 6,
            min: this.filters.number ? this.filters.number : 0,
            version: Object.keys(versions).filter(d =>
              this.filters.billstatuses.includes(versions[d].brief)
            ),
            sponsor:
              this.filters.sponsor != ""
                ? {
                    sponsors: {
                      items: [
                        {
                          fullName: this.filters.sponsor
                        }
                      ]
                    }
                  }
                : {},

            cosponsor:
              this.filters.cosponsor != ""
                ? {
                    cosponsors: {
                      items: [
                        {
                          fullName: this.filters.cosponsor
                        }
                      ]
                    }
                  }
                : {}
          }
        })
        .then(response => {
          this.bills = response.data.bills;
          this.loading = false;
        })
        .catch(e => {
          console.error(e);
          this.$store.dispatch("setnotification", {
            notification: { error: e }
          });
        });
    }
  },
  created() {
    this.search();
  }
});
