































































import { Vue } from "vue-property-decorator";
import { GET_USER_QUERY, GET_USER_VOTED_BILLS } from "@/constants/graphql";
import { User } from "@/types";

export default Vue.extend({
  name: "Profile" as string,
  props: ["username"],
  data() {
    return {
      user: {} as User,
      votes: [],
      option: "up",
      page: 1,
      options: [
        { label: "Endorses", value: "up" },
        { label: "Opposes", value: "down" }
      ]
    };
  },
  apollo: {
    user: {
      query: GET_USER_QUERY,
      variables(): {} {
        return {
          username: this.username
        };
      },
      update: data => {
        const user = data.users[0];
        if (!user) {
          window.location.href = "/404";
        }
        return user;
      }
    },
    votes: {
      query: GET_USER_VOTED_BILLS,
      variables(): {} {
        return {
          authid: this.user.id
        };
      },
      skip() {
        return !this.$data.user.id;
      }
    }
  },
  computed: {
    bills() {
      return this.$data.votes
        .filter(d => d.vote_option == this.option)
        .map(d => d.bill);
    }
  }
});
