











































































































import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "TocItem" as string,
  props: ["provision", "slug", "expanded"],
  data() {
    return {
      notoc: ["whereas", "toc"],
      onlywithheader: [
        "paragraph",
        "subparagraph",
        "subsection",
        "appropriations-small"
      ],
      onlychildren: ["legis-body", "quoted-block"],
      noheaderenum: ["resolution-body", "preamble"],
      userexpanded: false
    };
  },
  computed: {
    selectedlocation() {
      return this.$store["state"].location;
    },
    selected() {
      return this.provision["location"] == this.$store["state"].location;
    }
  },
  methods: {
    toLoc(provision) {
      this.$store.dispatch("setlocation", { loc: provision.location });
    },
    toDisplay(provision) {
      const ptype = provision.provision_type;
      const h = provision.header ? provision.header : "", e = provision.enum ? provision.enum : "";
      if (this.$data.notoc.includes(ptype)) {
        return false;
      } else if (h == "" && e == "" && !this.$data.noheaderenum.includes(ptype)) {
        return false;
      } else if (
        this.$data.onlywithheader.includes(ptype) &&
        h == ""
      ) {
        return false;
      } else if (h.toLowerCase().match(/\([\w\s]+\)/)) {
        return false;
      }
      return true;
    }
  }
});
