





















































































































































































































import Legislation from "@/components/Legislation.vue";
import { Bill } from "@/types";
import CommentsComponent from "@/components/CommentsComponent.vue";
import { Vue } from "vue-property-decorator";
import { printType, printClass, versions, OrdinalSuffix } from "@/utils";
import {
  GET_LEGISLATION_QUERY,
  BILL_VERSIONS_QUERY,
  SEARCH_HEADERS_QUERY
} from "@/constants/graphql";
import TocComponent from "@/components/TocComponent.vue";
import Loader from "@/components/Loader.vue";

Vue.component("legis", Legislation);
Vue.component("comments", CommentsComponent);
Vue.component("toc", TocComponent);
Vue.component("loader", Loader);

export default Vue.extend({
  name: "Content" as string,
  props: ["slug", "loc"],
  data() {
    return {
      legislation: {} as Bill,
      currenttab: "Contents",
      billversions: [] as string[],
      allversions: versions,
      fullsummary: false,
      headerlocations: [],
      searchloading: false,
      searchindex: -2
    };
  },
  computed: {
    leg(): string {
      return "BILLS-" + this.slug;
    },
    provision() {
      return this.$store.state.provision;
    },
    summary() {
      const items = this.$data.legislation.status?.summaries?.billSummaries
        ?.items;
      const txt = items ? items[items.length - 1].text : "";
      if (!txt) {
        return "";
      }
      return txt.replace(
        /(<p>)?\s*<(b|strong)>.+<\/(b|strong)>\s*(<\/p>)?|<br>/g,
        " "
      );
    },
    version: {
      get: function() {
        return this.$data.legislation.version;
      },
      set: function(newValue: string) {
        const slug = this.slug.replace(this.legislation.version, newValue);
        this.$router.push({ name: "Content", params: { slug: slug } });
      }
    }
  },
  watch: {
    provision: function(newVal) {
      if (newVal) {
        this.currenttab = "Discussion";
      }
    },
    searchindex: function(newVal) {
      if (newVal > -1 && this.headerlocations[newVal] != undefined) {
        this.$store.dispatch("setlocation", {
          loc: this.headerlocations[newVal]
        });
      }
    }
  },
  methods: {
    PrintType(t: string) {
      return printType(t);
    },
    SearchHeaders(event) {
      this.searchloading = true;
      this.$apollo
        .query({
          query: SEARCH_HEADERS_QUERY,
          variables: {
            leg: this.leg,
            term: `%${event.target.value}%`
          }
        })
        .then(response => {
          this.headerlocations = response.data.provisions.map(d => d.location);
          if (response.data.provisions.length > 0) {
            if (this.searchindex == 0) {
              this.$store.dispatch("setlocation", {
                loc: this.headerlocations[0]
              });
            }
            this.searchindex = 0;
          } else {
            this.searchindex = -1;
          }
        })
        .catch(e => {
          console.error(e);
          this.$store.dispatch("setnotification", {
            notification: { error: e }
          });
        })
        .finally(() => {
          this.searchloading = false;
        });
    }
  },
  filters: {
    OrdinalSuffix,
    printType,
    printClass
  },
  apollo: {
    legislation: {
      query: GET_LEGISLATION_QUERY,
      variables(): {} {
        return {
          leg: this.leg,
          userid: this.$store.getters.isLoggedIn ? this.$store.state.user.id : 0
        };
      },
      update: data => {
        const bill = data.bills[0];
        if (!bill) {
          window.location.href = "/404";
          // Can't use this.$router here?
        }
        document.title = `${bill.bill_type.toUpperCase()} ${
          bill.number
        } - PopuLegis`;
        return bill;
      },
      fetchPolicy: "cache-and-network"
    },
    billversions: {
      query: BILL_VERSIONS_QUERY,
      variables(): {} {
        return {
          congress: this.legislation.congress,
          number: this.legislation.number,
          type: this.legislation.bill_type
        };
      },
      skip(): {} {
        return !this.legislation?.congress;
      },
      update: data => data.bills.map(d => d.version)
    }
  },
  created() {
    this.$store.dispatch("setlocation", { loc: this.loc });
    this.$store.dispatch("setprovision", { provision: null });
  }
});
