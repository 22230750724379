
































































































































































































































































































































































import { Vue } from "vue-property-decorator";
import { Bill } from "@/types";
import BillCard from "@/components/BillCard.vue";
import { SEARCH_BILLS_QUERY } from "@/constants/graphql";
import { uniquebills, printType } from "@/utils";
import Loader from "@/components/Loader.vue";

Vue.component("bill", BillCard);
Vue.component("loader", Loader);

export default Vue.extend({
  name: "TopBar" as string,
  data() {
    return {
      bills: [] as Bill[],
      query: "",
      loading: false,
      dropdown: false,
      menu: false,
      searchdropdown: false,
      searchhover: false,
      mobilemenu: false,
      searched: false
    };
  },
  computed: {
    uniquebills() {
      const bills = uniquebills(this.bills);
      return bills;
    }
  },
  methods: {
    // Log the user in
    login(): void {
      this.$router.push({ name: "Login" });
    },
    // Log the user out
    logout(): void {
      this.$store
        .dispatch("logout")
        .then(resp => {
          if (resp.data.ok) {
            this.dropdown = false;
          }
        })
        .catch(err => console.log(err));
    },
    PrintType(t: string) {
      return printType(t);
    },
    search() {
      const q = this.query;
      if (q == "") {
        return;
      }
      this.searchdropdown = true;
      const sn = `%${
        (q.match(
          /^(\d{3})?(hr|s|hres|sres|hjres|sjres|hconres|sconres)?(\d{1,4})(ath|ats|cps|eah|eas|eh|es|ih|is|pcs|rcs|rds|rfs|rh|rs|enr)?$/g
        ) || ["NOT A SLUG"])[0]
      }%`;
      const st = sn == "%NOT A SLUG%" ? `%${q}%` : "NOT A TITLE";
      const vars = {
        searchStr: st,
        searchSlug: sn
      };
      this.loading = true;
      console.log(q, vars);
      this.$apollo
        .query({
          query: SEARCH_BILLS_QUERY,
          variables: vars
        })
        .then(response => {
          this.bills = response.data.bills;
          this.loading = false;
        })
        .catch(e => {
          console.error(e);
          this.$store.dispatch("setnotification", {
            notification: { error: e }
          });
        })
        .finally(() => {
          this.searched = true;
        });
    }
  },
  mounted() {
    this.$router.afterEach(() => {
      //this.$store.dispatch('setPrev', from.path);
      this.dropdown = false;
      this.searchhover = false;
      this.searchdropdown = false;
      this.menu = false;
      this.mobilemenu = false;
    });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.$store.dispatch("setPrev", from.path));
  },
  beforeCreate() {
    this.$store
      .dispatch("refreshToken")
      .then(resp => {
        console.log(resp);
      })
      .catch(err => console.log(err));
  }
});
