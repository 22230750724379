var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.toDisplay(_vm.provision) && !_vm.onlychildren.includes(_vm.provision.provision_type)
    )?_c('div',{staticClass:"w-full pb-0.5"},[_c('span',{staticClass:"flex items-baseline font-sans text-xs font-medium"},[_c('a',{staticClass:"text-coolgray-500 mr-1 text-base leading-none",on:{"click":function($event){_vm.userexpanded = !_vm.userexpanded}}},[_vm._v(" "+_vm._s(_vm.provision.children.filter(function (d) { return _vm.toDisplay(d); }).length == 0 ? " " : _vm.expanded || _vm.userexpanded ? "▾" : "▸")+" ")]),_c('a',{class:((_vm.selected || _vm.expanded
              ? 'text-coolgray-700'
              : 'text-blue-500 hover:text-blue-700') + "\n          " + (_vm.selected ? 'bg-yellow-50' : '') + "\n          "),on:{"click":function($event){return _vm.toLoc(_vm.provision)}}},[(
            ['section', 'subsection', 'paragraph', 'subparagraph'].includes(
              _vm.provision.provision_type
            )
          )?_c('span',[_vm._v(" "+_vm._s(_vm.provision.enum)+" "+_vm._s(_vm.provision.header)+" ")]):(
            [
              'division',
              'chapter',
              'part',
              'title',
              'subtitle',
              'subpart',
              'subchapter'
            ].includes(_vm.provision.provision_type)
          )?_c('span',[_vm._v(" "+_vm._s(_vm.provision.provision_type.charAt(0).toUpperCase() + _vm.provision.provision_type.slice(1))+" "+_vm._s(_vm.provision.enum)+" "),(_vm.provision.header != '')?_c('span',[_vm._v(" - "+_vm._s(_vm.provision.header))]):_vm._e()]):(_vm.provision.provision_type == 'resolution-body')?_c('span',[_vm._v(" Resolution Body ")]):(_vm.provision.provision_type == 'preamble')?_c('span',[_vm._v(" Preamble ")]):(_vm.provision.provision_type.includes('appropriations'))?_c('span',[_vm._v(" "+_vm._s(_vm.provision.header)+" ")]):_vm._e()])])]):_vm._e(),(
      (_vm.expanded || _vm.userexpanded) &&
        _vm.provision.children.filter(function (d) { return _vm.toDisplay(d); }).length > 0
    )?_c('div',{staticClass:"flex flex-col space-y-0.5",class:("" + (_vm.toDisplay(_vm.provision) &&
        !_vm.onlychildren.includes(_vm.provision.provision_type)
          ? 'ml-3'
          : ''))},_vm._l((_vm.provision.children),function(child,i){return _c('TocItem',{key:i,attrs:{"provision":child,"slug":_vm.slug,"expanded":_vm.expanded &&
          (child.provision_type == 'quoted-block' ||
            (child.location <= _vm.selectedlocation &&
              (i == _vm.provision.children.length - 1 ||
                _vm.provision.children[i + 1].location > _vm.selectedlocation)))}})}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }