<template>
  <div class="flex items-center bg-coolgray-50">
    <div
      v-for="(v, i) of options"
      class="flex items-center space-x-1 py-1 px-2 text-coolgray-500 cursor-pointer border-b-4 text-xs font-semibold uppercase"
      :class="
        `${
          value == v.value
            ? 'border-coolgray-500 text-coolgray-900'
            : 'hover:border-coolgray-400 border-coolgray-300'
        }`
      "
      @click="handleInput(v.value)"
      :key="i"
    >
      <span v-if="v.icon != null" class="material-icons-outlined text-base">{{
        v.icon
      }}</span>
      <span class="py-1">{{ v.label }}</span>
    </div>
    <div class="flex-grow border-coolgray-300 border-b-4 py-2 text-xs">
      &nbsp;
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: ["value", "options"],
  data() {
    return {
      content: this.value
    };
  },
  methods: {
    handleInput(v) {
      this.$emit("input", v);
    }
  }
};
</script>
