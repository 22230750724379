import gql from "graphql-tag";

export const GET_USER_QUERY = gql`
  query GetUser($username: String!) {
    users(where: { username: { _eq: $username } }) {
      id
      username
      lastname
      firstname
      zipcode
      city
      state
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $authid: String!
    $lastname: String
    $firstname: String
    $zipcode: String
    $city: String
    $state: String
  ) {
    update_users_by_pk(
      pk_columns: { username: $authid }
      _set: {
        lastname: $lastname
        firstname: $firstname
        zipcode: $zipcode
        city: $city
        state: $state
      }
    ) {
      username
      lastname
      firstname
      zipcode
      city
      state
    }
  }
`;

export const GET_USER_VOTED_BILLS = gql`
  query GetUserVotedBills($authid: Int!) {
    votes(
      where: {
        provision_tag: { _eq: "0" }
        user_id: { _eq: $authid }
        comment_id: { _is_null: true }
      }
    ) {
      bill {
        packageid
        congress
        bill_type
        number
        last_modified
        comments_count
        current_chamber
        downvotes_count
        origin_chamber
        short_title
        date_issued
        official_title
        template
        upvotes_count
        version
        sponsor
        slug
        sponsor_: status(path: "sponsors.items[0]")
        cosponsors: status(path: "cosponsors.items")
        last_update
        last_action
      }
      vote_option
      provision_tag
      comment_id
    }
  }
`;

export const GET_USER_VOTED_PROVISIONS = gql`
  query GetUserVotedProvisions($userid: Int!, $packageid: String!) {
    votes(
      where: {
        provision_tag: { _neq: "0" }
        user_id: { _eq: $userid }
        comment_id: { _is_null: true }
        packageid: { _eq: $packageid }
      }
    ) {
      provision {
        location
        parent_location
      }
      vote_option
      provision_tag
    }
  }
`;

export const SEARCH_BILLS_QUERY = gql`
  query SearchBills($searchStr: String, $searchSlug: String) {
    bills(
      where: {
        _or: [
          { official_title: { _ilike: $searchStr } }
          { short_title: { _ilike: $searchStr } }
          { slug: { _ilike: $searchSlug } }
        ]
      }
      limit: 10
      order_by: { congress: desc, date_issued: desc, last_action: desc }
    ) {
      packageid
      congress
      bill_type
      number
      short_title
      official_title
      date_issued
      version
      sponsor
      slug
      sponsor_: status(path: "sponsors.items[0]")
      cosponsors: status(path: "cosponsors.items")
      upvotes_count
      downvotes_count
      comments_count
    }
  }
`;

export const SEARCH_HEADERS_QUERY = gql`
  query SearchProvisionHeaders($leg: String!, $term: String!) {
    provisions(
      where: { packageid: { _eq: $leg }, header: { _ilike: $term } }
      order_by: { location: asc }
      limit: 30
    ) {
      location
    }
  }
`;

export function BILLS_QUERY(orderBy: string, addlfilters: string) {
  return gql`
    query GetBills($limit: Int, $offset: Int, $congress: [Int!], $billtype: [String!], $max: Int!, $min: Int!, $version: [String!], $sponsor: jsonb, $cosponsor: jsonb) {
      bills(limit: $limit, offset: $offset, order_by: ${orderBy}, where: {
        _and: [
          { congress: { _in: $congress } }
          { bill_type: { _in: $billtype } }
          { number: { _lte: $max } }
          { number: { _gte: $min } }
          { version: { _in: $version } }
          { status: { _contains: $sponsor } }
          { status: { _contains: $cosponsor } }
          ${addlfilters}
        ]
      }) {
        packageid
        congress
        bill_type
        number
        last_modified
        date_issued
        comments_count
        current_chamber
        downvotes_count
        origin_chamber
        short_title
        official_title
        template
        upvotes_count
        version
        sponsor
        slug
        sponsor_: status(path: "sponsors.items[0]")
        cosponsors: status(path: "cosponsors.items")
        last_update
        last_action
      }
    }
  `;
}

export function GET_PROVISIONS_QUERY(start: boolean) {
  return gql`
    query GetProvisions($leg: String, $anchor: Int, $limit: Int, $userid: Int) {
      provisions(
        where: {
          packageid: { _eq: $leg }
          location: { ${start ? "_gte" : "_lte"}: $anchor }
          provision_tag: {_nlike: "%toc-entry%"}
        },
        order_by: { location: ${start ? "asc" : "desc"} },
        limit: $limit
      ) {
        packageid
        location
        parent_location
        level
        provision_type
        enum
        header
        text
        provision_tag
        display_tag
        votes(where: { user_id: { _eq: $userid } }) {
          user_id
          vote_option
        }
      }
    }
  `;
}

export const GET_PROVISIONS_LOCATIONS = gql`
  query GetProvisions(
    $leg: String!
    $locations: [Int!]
    $parent_locs: [Int!]
    $userid: Int
  ) {
    provisions(
      where: {
        packageid: { _eq: $leg }
        _or: [
          { location: { _in: $locations } }
          { location: { _in: $parent_locs } }
          { parent_location: { _in: $locations } }
        ]
        provision_type: { _neq: "toc-entry" }
      }
      order_by: { location: asc }
    ) {
      packageid
      location
      parent_location
      level
      provision_type
      enum
      header
      text
      provision_tag
      display_tag
      votes(where: { user_id: { _eq: $userid } }) {
        user_id
        vote_option
      }
    }
  }
`;

export const GET_TOC_PROVISIONS_QUERY = gql`
  query GetTocProvisions($leg: String) {
    provisions(
      where: { packageid: { _eq: $leg }, level: { _lte: 6 } }
      order_by: { location: asc }
    ) {
      location
      parent_location
      level
      provision_type
      enum
      header
    }
  }
`;

export const GET_LEGISLATION_QUERY = gql`
  query GetLegislation($leg: String, $userid: Int) {
    bills(where: { packageid: { _eq: $leg } }) {
      bill_type
      current_chamber
      origin_chamber
      short_title
      template
      version
      congress
      date_issued
      last_modified
      number
      official_title
      packageid
      slug
      session
      sponsor
      status
      provisions_aggregate {
        aggregate {
          count
        }
      }
      votes(
        where: {
          _and: [{ user_id: { _eq: $userid } }, { provision_tag: { _eq: "0" } }]
        }
      ) {
        user_id
        vote_option
      }
    }
  }
`;

export const BILL_VERSIONS_QUERY = gql`
  query BillVersions($congress: Int!, $type: String!, $number: Int!) {
    bills(
      where: {
        congress: { _eq: $congress }
        bill_type: { _eq: $type }
        number: { _eq: $number }
      }
      order_by: { date_issued: desc }
    ) {
      version
    }
  }
`;

export const GET_COMMENTS_QUERY = gql`
  query GetComments($packageid: String!, $userid: Int) {
    comments(
      where: { packageid: { _eq: $packageid } }
      order_by: { created_at: desc }
    ) {
      comment
      comment_id
      packageid
      upvotes_count
      downvotes_count
      user {
        username
        firstname
        lastname
      }
      updated_at
      deleted_at
      votes(where: { user_id: { _eq: $userid } }) {
        user_id
        vote_option
      }
      provision {
        location
        display_tag
        provision_tag
      }
    }
  }
`;

export const GET_TOP_COMMENTS_QUERY = gql`
  query GetComments($packageid: String!, $userid: Int) {
    comments(
      where: { packageid: { _eq: $packageid } }
      order_by: { upvotes_count: desc }
    ) {
      comment
      comment_id
      packageid
      upvotes_count
      downvotes_count
      user {
        username
        firstname
        lastname
      }
      updated_at
      deleted_at
      votes(where: { user_id: { _eq: $userid } }) {
        user_id
        vote_option
      }
      provision {
        location
        display_tag
        provision_tag
      }
    }
  }
`;

export const USER_DAY_COMMENTS_QUERY = gql`
  query UserDayComments($userid: Int!, $date: date) {
    comments(
      where: { user_id: { _eq: $userid }, created_date: { _eq: $date } }
      order_by: { user_day_comment: asc }
    ) {
      user_day_comment
    }
  }
`;

export const CREATE_COMMENT_MUTATION = gql`
  mutation CreateCommentMutation(
    $userid: Int!
    $packageid: String!
    $comment: String!
    $provisiontag: String
    $userDayComment: Int!
  ) {
    insert_comments_one(
      object: {
        comment: $comment
        user_id: $userid
        packageid: $packageid
        provision_tag: $provisiontag
        user_day_comment: $userDayComment
      }
    ) {
      comment_id
    }
  }
`;

export const DELETE_COMMENT_MUTATION = gql`
  mutation DeleteComment($id: Int!, $deletedAt: timestamp!) {
    update_comments_by_pk(
      pk_columns: { comment_id: $id }
      _set: { deleted_at: $deletedAt }
    ) {
      comment_id
      comment
      deleted_at
    }
  }
`;

export const CREATE_VOTE_MUTATION = gql`
  mutation CreateVoteMutation(
    $userid: Int!
    $packageid: String
    $provisionTag: String
    $commentid: Int
    $voteoption: vote_option!
  ) {
    insert_votes_one(
      object: {
        packageid: $packageid
        provision_tag: $provisionTag
        user_id: $userid
        comment_id: $commentid
        vote_option: $voteoption
      }
    ) {
      id
    }
  }
`;

export const DELETE_VOTE_MUTATION = gql`
  mutation DeleteVoteMutation(
    $userid: Int!
    $packageid: String
    $provisionTag: String
    $commentid: Int
    $voteoption: vote_option
  ) {
    delete_votes(
      where: {
        _and: [
          { comment_id: { _eq: $commentid } }
          { packageid: { _eq: $packageid } }
          { provision_tag: { _eq: $provisionTag } }
          { user_id: { _eq: $userid } }
        ]
      }
    ) {
      affected_rows
      returning {
        id
        vote_option
      }
    }
  }
`;
