

































































import { Vue } from "vue-property-decorator";
import { Provision } from "@/types";
import ProvisionComponent from "@/components/ProvisionComponent.vue";
import _ from "lodash";
import VueObserveVisibility from "vue-observe-visibility";
import { listtotree } from "@/utils";
import {
  GET_PROVISIONS_QUERY,
  GET_USER_VOTED_PROVISIONS,
  GET_PROVISIONS_LOCATIONS
} from "@/constants/graphql";
import Loader from "@/components/Loader.vue";

Vue.component("loader", Loader);
Vue.component("prov", ProvisionComponent);
Vue.use(VueObserveVisibility);

export default Vue.extend({
  name: "Legislation" as string,
  props: ["slug", "loc", "legislation"],
  data() {
    return {
      provisions: [] as Provision[],
      votedprovisions: [] as Provision[],
      filter: "all",
      votes: [] as any[],
      loading: false
    };
  },
  computed: {
    leg(): string {
      return "BILLS-" + this.slug;
    },
    start(): number {
      return this.provisions[0]?.location;
    },
    end(): number {
      return this.provisions[this.provisions.length - 1]?.location;
    },
    tree(): Provision[] {
      const tree = listtotree(
        this.filter == "all" ? this.provisions : this.votedprovisions
      );
      return tree.children;
    },
    verified(): boolean {
      return this.$store.getters.authLoading
        ? false
        : this.$store.getters.verified;
    },
    location(): number {
      return this.$store.state.location;
    },
    votelocations(): number[] {
      return this.votes.map(d => d.provision.location);
    },
    voteparentlocations(): number[] {
      return this.votes.map(d => d.provision.parent_location);
    },
    endofbill(): boolean {
      return this.end >= this.legislation.provisions_aggregate?.aggregate.count;
    }
  },
  watch: {
    location(newVal, oldVal) {
      if (newVal) {
        this.fetchProvisions(true, Math.max(1, newVal - 50), 100);
      }
    },
    verified(newVal) {
      if (newVal) {
        this.fetchProvisions(true, this.location ? this.location - 50 : 1, 100);
      }
    }
  },
  methods: {
    fetchProvisions(
      start_: boolean,
      anchor_: number,
      limit_: number,
      scroll_ = true
    ) {
      this.loading = true;
      this.$apollo
        .query({
          query: GET_PROVISIONS_QUERY(start_),
          variables: {
            leg: this.leg,
            anchor: anchor_,
            limit: limit_,
            userid: this.verified ? this.$store.state.user.id : 0
          }
        })
        .then(response => {
          const prov_ = response.data.provisions;
          this.provisions = start_ ? prov_ : prov_.reverse();
          setTimeout(() => {
            this.loading = false;
            const el = this.$el.querySelector(".selectedprovision");
            if (el instanceof HTMLElement && scroll_) {
              el.scrollIntoView({ block: "center" });
            }
          }, 100); // Don't recall why this timeout is here
        })
        .catch(e => {
          console.error(e);
          this.$store.dispatch("setnotification", {
            notification: { error: e }
          });
          setTimeout(() => {
            this.loading = false;
          }, 100);
        });
    },
    showMore(up: boolean) {
      let start_, anchor_, limit_;
      const nprov = this.provisions.length;
      if (up) {
        start_ = false;
        anchor_ = this.provisions[nprov > 450 ? nprov - 50 : nprov - 1]
          .location;
        limit_ = Math.min(nprov + 50, 500);
      } else {
        start_ = true;
        anchor_ = this.provisions[nprov > 450 ? 50 : 0].location;
        limit_ = Math.min(nprov + 50, 500);
      }
      this.fetchProvisions(start_, anchor_, limit_, false);
    }
  },
  apollo: {
    votes: {
      query: GET_USER_VOTED_PROVISIONS,
      variables(): {} {
        return {
          userid: this.verified ? this.$store.state.user.id : 0,
          packageid: this.leg
        };
      },
      skip(): {} {
        return !this.verified;
      }
    },
    votedprovisions: {
      query: GET_PROVISIONS_LOCATIONS,
      variables(): {} {
        return {
          leg: this.leg,
          locations: this.votelocations,
          parent_locs: this.voteparentlocations,
          userid: this.verified ? this.$store.state.user.id : 0
        };
      },
      skip(): {} {
        return !this.votelocations;
      },
      update: data => data.provisions
    }
  },
  mounted() {
    this.fetchProvisions(true, this.location ? this.location - 50 : 1, 100);
  }
});
