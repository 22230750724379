<template>
  <svg
    class="mx-auto animate-spin"
    viewBox="0 0 32 32"
    :class="`${size == 2 ? 'w-6 h-6' : 'w-3 h-3'}`"
  >
    <circle
      cx="16"
      cy="16"
      r="12"
      fill="transparent"
      stroke-width="4"
      stroke="#374151"
      stroke-dasharray="100%"
    />
  </svg>
</template>

<script>
export default {
  name: "Loader",
  props: {
    size: {
      type: Number,
      required: false,
      default: 2
    }
  }
};
</script>
