

















































































































import { Vue } from "vue-property-decorator";
import { versions } from "@/utils";
import { members } from "@/constants/members";

export default Vue.extend({
  name: "BillFilters",
  props: ["value"],
  data() {
    return {
      allmembers: members
        .sort()
        .map(d => `<option>${d}</option>`)
        .join(""),
      allbilltypes: [
        "hr",
        "s",
        "hres",
        "sres",
        "hjres",
        "sjres",
        "hconres",
        "sconres"
      ],
      allstatuses: [...new Set(Object.values(versions).map(d => d.brief))],
      allcongresses: [...Array(118).keys()].slice(103).reverse(),
      filters: {
        sponsor: "",
        sponsorcosponsor: true,
        cosponsor: "",
        billstatuses: [...new Set(Object.values(versions).map(d => d.brief))],
        billtypes: ["hr", "s", "hjres", "sjres"],
        congress: [116, 117],
        number: undefined
      }
    };
  },
  methods: {
    updatefilters() {
      this.$emit("input", this.filters);
    }
  }
});
