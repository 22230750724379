








































































import { Vue } from "vue-property-decorator";
import { cardTimeFormat, printType, versions, metricFormat } from "@/utils";

export default Vue.extend({
  name: "BillCard" as string,
  props: ["bill", "context", "loading"],
  computed: {
    numStages() {
      return versions[this.bill.version].num_stages;
    },
    iconsmetrics() {
      return [
        ["thumb_up", this.bill.upvotes_count],
        ["thumb_down", this.bill.downvotes_count],
        ["comment", this.bill.comments_count],
        [
          "verified",
          this.bill.cosponsors
            ? this.bill.cosponsors.length
            : 0,
        ],
      ];
    },
  },
  methods: {
    PrintType(t: string) {
      return printType(t);
    },
  },
  filters: {
    cardTimeFormat,
    metricFormat,
  },
});
